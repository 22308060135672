.content-navigation {
    &.row {
        gap: $grid-gutter-width 0;
    }

    .col,
    [class*="col-"] {
        display: flex;
        flex-direction: column;
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        border: 1px solid $color__gray;
        border-radius: $border__radius;
        transition: border-color .2s linear;

        &.content-navigation__item--has-link {
            @include hover-focus-within {
                border-color: $color__font;

                p {
                    &[aria-hidden='true'] {
                        img {
                            transform: translateX(4px);
                        }
                    }
                }

                a.stretched-link {
                    text-decoration: underline;
                    text-decoration-thickness: .08em;
                }
            }
        }

        &.content-navigation__item--has-image {
            .content-navigation {
                &__body {
                    border-radius: 0 0 $border__radius $border__radius;
                }
            }
        }

        &:not(.content-navigation__item--has-image):not(.content-navigation__item--has-preview-image) {
            figure {
                position: relative;
                padding-top: 66.666667%;
                height: 0;

                > img {
                    position: absolute;
                    top: 50%; left: 50%;
                    filter: $filter__gray;
                    transform: translate(-50%, -50%);
                }
            }
        }

        figure {
            flex-shrink: 0;
            background-color: $color__gray--light;
            border-bottom: 1px solid $color__gray;
            border-radius: $border__radius $border__radius 0 0;
            overflow: hidden;
        }

        a.stretched-link {
            color: $color__font;
            text-decoration: none;
        }

        p {
            &[aria-hidden='true'] {
                img {
                    transition: transform .2s ease-in-out;
                }
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 24px;
        background-color: $color__white;
        border-radius: $border__radius;

        @include media-breakpoint-up(sm) {
            padding: 48px 32px;
        }
    }
}
