@use "sass:math";

@mixin mq($breakpoint, $type: min) {
    @if map-has-key($grid-breakpoints, $breakpoint) {
        $width: map-get($grid-breakpoints, $breakpoint);
        @if $type == max {
            $width: $width - 1px;
        }
        @media (#{$type}-width: $width) {
            @content;
        }
    }
}

// https://css-tricks.com/snippets/css/fluid-typography/
//@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
//    $u1: unit($min-vw);
//    $u2: unit($max-vw);
//    $u3: unit($min-font-size);
//    $u4: unit($max-font-size);
//
//    @if $u1 == $u2 and $u3 == $u4 {
//        & {
//            font-size: $min-font-size;
//            @media (min-width: $min-vw) {
//                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
//                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * (((var(--vw, 1vw) * 100) - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
//            }
//            @media (min-width: $max-vw) {
//                font-size: $max-font-size;
//            }
//        }
//    } @else {
//        @if $u1 != $u2 {
//            @error 'Mismatch of breakpoint units';
//        }
//        @if $u3 != $u4 {
//            @error 'Mismatch of font size units';
//        }
//    }
//}
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    @if unit($min-vw) == 'px' {
        $min-vw: strip-unit($min-vw) * .0625;
    } @else {
        $min-vw: strip-unit($min-vw);
    }
    @if unit($max-vw) == 'px' {
        $max-vw: strip-unit($max-vw) * .0625;
    } @else {
        $max-vw: strip-unit($max-vw);
    }
    @if unit($min-font-size) == 'px' {
        $min-font-size: strip-unit($min-font-size) * .0625;
    } @else {
        $min-font-size: strip-unit($min-font-size);
    }
    @if unit($max-font-size) == 'px' {
        $max-font-size: strip-unit($max-font-size) * .0625;
    } @else {
        $max-font-size: strip-unit($max-font-size);
    }

    & {
        font-size: #{$min-font-size}rem;
        @media (min-width: #{$min-vw}rem) {
            font-size: calc(#{$min-font-size}rem + #{$max-font-size - $min-font-size} * ((100vw - #{$min-vw}rem) / #{$max-vw - $min-vw}));
        }
        @media (min-width: #{$max-vw}rem) {
            font-size: #{$max-font-size}rem;
        }
    }
}

@mixin margins-headings {
    h1,
    .h1 {
        margin-bottom: $margin__headings--h1;
    }

    h2,
    .h2 {
        margin-bottom: $margin__headings--h2;
    }

    h3, h4, h5, h6,
    .h3, .h4, .h5, .h6 {
        margin-bottom: $margin__headings--h3;
    }
}

@mixin margins-block-level-elements {
    > * {
        + *:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
            margin-top: $margin__block;
        }

        + h1,
        + h2,
        + h3,
        + h4,
        + h5,
        + h6 {
            margin-top: $margin__block--headings;
        }
    }
}

@mixin heading-subline($position: suffix, $size: $length__golden-ratio--major, $weight: null, $spacing: null, $color: null) {
    $selector: null;
    @if $position == suffix {
        $selector: last-child;
    } @else if $position == prefix {
        $selector: first-child;
    }

    span {
        display: block;

        @if $selector {
            + span:#{$selector} {
                font-size: #{$size}em;
                @if $weight {
                    font-weight: $weight;
                }
                @if $spacing {
                    letter-spacing: #{$spacing}em;
                }
                @if $color {
                    color: $color;
                }
            }
        }
    }
}

@mixin hyphenate {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

@mixin container-class-selector {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl,
    .container-fluid {
        @content;
    }
}

@mixin container-child-combinator {
    > .container,
    > .container-sm,
    > .container-md,
    > .container-lg,
    > .container-xl,
    > .container-xxl,
    > .container-fluid {
        @content;
    }
}

@mixin container-general-sibling-combinator {
    ~ .container,
    ~ .container-sm,
    ~ .container-md,
    ~ .container-lg,
    ~ .container-xl,
    ~ .container-xxl,
    ~ .container-fluid {
        @content;
    }
}

@mixin container-adjacent-sibling-combinator {
    + .container,
    + .container-sm,
    + .container-md,
    + .container-lg,
    + .container-xl,
    + .container-xxl,
    + .container-fluid {
        @content;
    }
}

@mixin truncate-text($rows: 3, $line-height: 1.5, $height: auto) {
    overflow: hidden;

    @if $rows == 1 {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
    } @else {
        display: -webkit-box;
        -webkit-line-clamp: $rows;
        -webkit-box-orient: vertical;
        @if $height == fixed {
            height: #{$rows * $line-height}em;
        } @else {
            max-height: #{$rows * $line-height}em;
        }
        line-height: $line-height;
    }
}

@mixin aspect-ratio-before($ratio) {
    &::before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: math.div(1, $ratio * 100%);
    }

    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin magnifier-icon($size: 24px, $stroke: 2px, $color: currentColor) {
    &::before {
        content: "";
        position: absolute;
        top: calc(50% - (#{$size} * 0.5));
        left: calc(50% - (#{$size} * 0.5));
        width: calc(#{$size} * 0.85);
        height: calc(#{$size} * 0.85);
        border: $stroke solid $color;
        border-radius: 50%;
        pointer-events: none;
    }

    &::after {
        content: "";
        position: absolute;
        right: calc(50% - (#{$size} * 0.5));
        bottom: calc(50% - (#{$size} * 0.5));
        width: calc(#{$size} * 0.4);
        height: $stroke;
        background-color: $color;
        pointer-events: none;
        transform: translateY(50%) rotate(45deg);
        transform-origin: 100% center;
    }
}

@mixin arrow($pseudo: before, $type: left, $offset-x: 0, $offset-y: 50%, $size: 10px, $stroke: 2px, $color: currentColor) {
    &:#{$pseudo} {
        content: "";
        position: absolute;
        top: $offset-y;
        @if $type == right {
            right: $offset-x + $stroke;
        } @else if $type == center {
            left: calc(50% - #{$size * $length__hypotenuse * .25} + #{$stroke});
        } @else {
            left: $offset-x - ($size * $length__hypotenuse * .5) + ($stroke * 2);
        }
        width: $size;
        height: $size;
        border-top: $stroke solid $color;
        border-right: $stroke solid $color;
        pointer-events: none;
        @if $type == center {
            transform: translate(-50%, -50%) rotate(45deg);
        } @else {
            transform: translateY(-50%) rotate(45deg);
        }
    }

    //&::after {
    //	content: "";
    //	position: absolute;
    //	top: 0;
    //	left: 50%;
    //	width: 1px;
    //	height: 100%;
    //	background-color: #fff;
    //	transform: translateX(-50%);
    //}
}

@mixin input-placeholder($color: currentColor, $opacity: 1) {
    &::-webkit-input-placeholder { /* Chrome / Opera / Safari */
        color: $color;
        opacity: $opacity;
    }

    &:-moz-placeholder { /* Firefox 18- */
        color: $color;
        opacity: $opacity;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        color: $color;
        opacity: $opacity;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        color: $color;
        opacity: $opacity;
    }
}

@mixin text-selection($color: #fff, $background-color: #000) {
    &::-moz-selection {
        background: $background-color;
        color: $color;
        text-shadow: none;
    }

    &::selection {
        background: $background-color;
        color: $color;
        text-shadow: none;
    }
}

@mixin moz-scrollbar($scrollbar-width: thin, $scrollbar-color: transparent, $thumb-color: #000) {
    @media screen and (min--moz-device-pixel-ratio: 0) {
        scrollbar-color: $thumb-color $scrollbar-color;
        scrollbar-width: $scrollbar-width;
    }
}

@mixin webkit-scrollbar($scrollbar-size: 10px, $scrollbar-color: transparent, $thumb-size: 6px, $thumb-color: #000, $thumb-opacity: .3, $thumb-rounded: true) {
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: $scrollbar-size;
        height: $scrollbar-size;
    }

    &::-webkit-scrollbar-track {
        background-color: $scrollbar-color;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba($thumb-color, $thumb-opacity);
        @if $scrollbar-color == transparent {
            background-clip: padding-box;
        }
        border: (($scrollbar-size - $thumb-size) * .5) solid $scrollbar-color;
        @if $thumb-rounded {
            border-radius: $scrollbar-size * .5;
        }

        &:hover {
            background-color: $thumb-color;
        }
    }
}

@mixin hover-focus-visible {
    &:hover {
        @content;
    }

    &:focus-visible {
        @content;
    }
}

@mixin hover-focus-within {
    &:hover,
    &.focus-within {
        @content;
    }

    &:focus-within {
        @content;
    }
}

@mixin focus-within {
    &.focus-within {
        @content;
    }

    &:focus-within {
        @content;
    }
}

// https://larsmagnus.co/blog/focus-visible-within-the-missing-pseudo-class
// https://www.bram.us/2023/01/04/css-has-feature-detection-with-supportsselector-you-want-has-not-has/
@mixin focus-visible-within {
    @supports not selector(:has(*)) {
        @include focus-within {
            @content;
        }
    }

    @supports selector(:has(*)) {
        &:has(:focus-visible) {
            @content;
        }
    }
}

@mixin hover-focus-visible-within {
    @supports not selector(:has(*)) {
        @include hover-focus-within {
            @content;
        }
    }

    @supports selector(:has(*)) {
        &:hover {
            @content;
        }

        &:has(:focus-visible) {
            @content;
        }
    }
}

// https://stackoverflow.com/a/66365650
@mixin recolor($color: #000, $opacity: 1) {
    $r: math.div(red($color), 255);
    $g: math.div(green($color), 255);
    $b: math.div(blue($color), 255);
    $a: $opacity;

    // grayscale fallback if SVG from data url is not supported
    $lightness: lightness($color);
    filter: saturate(0%) brightness(0%) invert($lightness) opacity($opacity);

    // color filter
    $svg-filter-id: "recolor";
    filter: url('data:image/svg+xml;utf8,\
    <svg xmlns="http://www.w3.org/2000/svg">\
      <filter id="#{$svg-filter-id}" color-interpolation-filters="sRGB">\
        <feColorMatrix type="matrix" values="\
          0 0 0 0 #{$r}\
          0 0 0 0 #{$g}\
          0 0 0 0 #{$b}\
          0 0 0 #{$a} 0\
        "/>\
      </filter>\
    </svg>\
    ##{$svg-filter-id}');
}
