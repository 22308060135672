// https://sympli.io/blog/a-quick-guide-to-css-for-printable-webpages

//@import "../../../../scss/bootstrap/v5/bootstrap";
@import "../../areas/content-navigation/styles";

@media print {
    .before {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
        //padding: 8px 8px 8px 40px;
        padding: 8px;
        font-size: 16px;
        line-height: 24px;
        background-color: $color__gray--light;
        //background-image: url(/bundles/hitcomfrontend/img/material-design-icons/filled/report_problem.svg);
        //background-position: 0 0;
        //background-repeat: no-repeat;
        //background-size: 24px 24px;
        border: 1px solid $color__gray;
        border-radius: 4px;
    }

    .page {
        &__header,
        &__footer {
            display: none !important;
        }

        //&__main {
        //    &::before {
        //        content: "☠️";
        //        position: absolute;
        //        z-index: 9999;
        //        top: 50%;  left: 50%;
        //        font-size: 10cm;
        //        transform: translate(-50%, -50%);
        //    }
        //}
    }

    .section {
        &--crumb {
            display: none !important;
        }

        + .section {
            margin-top: 1cm;
        }
    }

    .content {
        &-navigation {
            .col,
            [class*=col-] {
                width: 33.333333% !important;
            }
        }

        &--accordion {
            .collapse {
                &:not(.show) {
                    display: block !important;
                }
            }
        }

        &--image-slider,
        &--image-gallery-additional,
        &--gufi-client-input {
            &::before {
                //content: url(/bundles/hitcomfrontend/img/material-design-icons/filled/report_problem.svg) " Dieser Inhalt wird beim Drucken ausgeblendet";
                content: "⚠️ Dieser Inhalt wird beim Drucken ausgeblendet";
                @extend .before;
            }

            > * {
                display: none !important;
            }
        }

        + .content {
            margin-top: .5cm;
        }
    }

    .accordion {
        &-collapse {
            &:not(.show) {
                display: block !important;
            }
        }
    }

    .image-slider {
        &::before {
            //content: url(/bundles/hitcomfrontend/img/material-design-icons/filled/report_problem.svg) " Dieser Inhalt wird beim Drucken ausgeblendet";
            content: "⚠️ Dieser Inhalt wird beim Drucken ausgeblendet";
            @extend .before;
        }

        > * {
            display: none !important;
        }
    }

    .skip-links {
        display: none !important;
    }

    .splide {
        &__footer,
        &__navigation {
            display: none !important;
        }
    }
}

@page {
    margin: 1cm;
}
